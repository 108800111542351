<template>
  <div>
    <b-card>
      <div>
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h3>
              <i class="fas fa-users-medical" /> เพิ่มลูกค้า
            </h3>
          </b-col>

          <!-- Search -->
          <b-col md="3" />
          <b-col
            cols="12"
            md="6"
          />
          <b-col
            md="1"
            class="p-0"
            style="margin-top: 20px"
          />
        </b-row>
      </div>
      <validation-observer ref="simpleRules">
        <b-row>
          <b-col md="12">
            <b-form-group
              label="ชื่อ - นามสกุล"
              label-for="customDelimiter"
            >
              <validation-provider
                #default="{ errors }"
                name="ชื่อ - นามสกุล"
                rules="required"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="fullname"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder=""
                />
                <small class="text-danger">{{
                  errors[0] ? `กรุณากรอกชื่อ` : ''
                }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>

          <b-col md="6">
            <b-form-group
              label="ยูสเซอร์เนม"
              label-for="customDelimiter"
            >
              <validation-provider
                #default="{ errors }"
                name="ยูสเซอร์เนม"
                rules="required"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="username"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder=""
                />
                <small class="text-danger">{{
                  errors[0] ? `กรุณากรอกยูสเซอร์เนม` : ''
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="รหัสผ่าน"
              label-for="customDelimiter"
            >
              <validation-provider
                #default="{ errors }"
                name="รหัสผ่าน"
                rules="required"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="password"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder=""
                />
                <small class="text-danger">{{
                  errors[0] ? `กรุณากรอกรหัสผ่าน` : ''
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="ธนาคาร"
              label-for="customDelimiter"
            >
              <v-select
                v-model="bank"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="banklist"
                :reduce="name => name.code"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="เลขที่บัญชี"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="acc_no"
                type="number"
                placeholder=""
              />
            </b-form-group>
          </b-col>
          <!-- <b-col md="12">
            <b-form-group
              label="เบอร์ Truemoney Wallet"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="trueaccno"
                type="number"
                placeholder=""
              />
            </b-form-group>
          </b-col> -->
          <b-col md="6">
            <b-form-group
              label="Email"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="email"
                type="text"
                placeholder=""
              />
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group
              label="ผู้แนะนำ"
              label-for="customDelimiter"
            >
              <b-form-input
                id="vi-first-name"
                v-model="telsearch"
                name="tel"
                type="text"
                placeholder="กรอกยูสเซอร์ผู้แนะนำ"
              />
            </b-form-group>
            <div>
              <span
                v-if="!member && search === true"
                style="color :red;"
              >
                ไม่พบข้อมูล
              </span>
              <span
                v-if="member && search === true"
                style="color :green;"
              >
                {{ member.name }} {{ member.surname }} : {{ member.username }}
              </span>
            </div>
          </b-col>
          <b-col md="1">
            <b-form-group
              class="mt-2"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="col-2.5 btn-icon"
                block
                @click="getUserslist(telsearch , 'name')"
              >
                <feather-icon icon="SearchIcon" />
                ค้นหา
              </b-button>
            </b-form-group>
          </b-col>

          <!-- <b-col md="12">
            <b-form-group
              label="สถานะ VIP"
              label-for="customDelimiter"
            >
              <b-form-checkbox
                checked="true"
                class="custom-control-success"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
            <hr>
          </b-col> -->

          <b-col
            md="12"
          >
            <div>
              <b-form-group
                label="ตั้งให้เป็นเซียน"
                label-for="customDelimiter"
              >
                <b-form-checkbox
                  v-model="zean"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>

              <b-form-group
                label="% ที่ให้(สำหรับเซียน) (หากกำหนดตรงนี้เป็น 0 จะใช้ค่าที่กำหนดไว้ในระบบ)"
                label-for="percent"
              >
                <b-form-input
                  id="percent"
                  v-model="zean_percent"
                  type="number"
                  placeholder=""
                />
              </b-form-group>
            </div>

          </b-col>

          <b-col
            md="12"
            class="mt-1"
          >
            <hr>
            <b-form-group>
              <b-button
                block
                variant="gradient-dark"
                @click.prevent="validationForm"
              >
                <i class="fad fa-cloud-download" />
                บันทึกข้อมูล
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  // BInputGroupAppend,
  BRow,
  BCol,
  BFormCheckbox,
  // BInputGroupPrepend,
  // BModal,
  BCard,
  // BTable,
  // BAvatar,
  BFormGroup,
  // BFormSelect,
  // BPagination,
  // BInputGroup,
  BFormInput,
  BButton,
  // BCardBody,
  VBToggle,
  // BOverlay,
  // BIconController,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
// import { kFormatter } from '@core/utils/filter'
// import moment from 'moment-timezone'
// import flatPickr from 'vue-flatpickr-component'
// import VueApexCharts from 'vue-apexcharts'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BFormCheckbox,
    // BInputGroupAppend,
    BRow,
    BCol,
    // BInputGroupPrepend,
    // BModal,
    BCard,
    // BTable,
    // BAvatar,
    BFormGroup,
    // BFormSelect,
    // BPagination,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    // BCardBody,
    // StatisticCardWithAreaChart,
    // // VueApexCharts,
    // BOverlay,
    // BIconController,
    vSelect,
    // flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      zean: false,
      zean_percent: 0,
      line_id: '',
      show: false,
      required,
      banklist: [],
      fullname: '',
      password: '',
      surname: '',
      bank: '',
      username: '',
      acc_no: '',
      trueaccno: '',
      email: '',
      status: '',
      invited_by: '',
      admintransfer: null,
      optionsMember: [],
      // options: [
      //   { value: 1, text: 'Active: เข้าเล่นได้ปกติ' },
      //   { value: 2, text: 'Locked: เข้าสู่ล็อบบี้ไม่ได้ เข้าเล่นไม่ได้' },
      //   { value: 0, text: 'Suspended: เข้าสู่ล็อบบี้ได้ แต่เข้าเล่นไม่ได้' },
      // ],
      optionsDep: [
        { value: 0, text: 'โอนผ่านบัญชีลูกค้าปกติ' },
        { value: 1, text: 'call center โอนผ่าน SCB เลขบัญชี ***7913 หรือ ***6330' },
      ],
      UserData: JSON.parse(localStorage.getItem('userData')),
      show2: false,
      search: false,
      telsearch: null,
      member: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.$http.get('/analytics/data').then(response => {
      this.data = response.data
    })
  },
  mounted() {
    this.Get_bank()
  },
  methods: {
    getUserslist(data, type) {
      this.optionsMemShow = true
      if (data) {
        const params = {
          search: data,
          type,
        }
        this.$http
          .post('/users/list/search', params)
          .then(response => {
            if (response.data.length > 0) {
              this.optionsCheck = true
              this.invited_by = response.data[0].invite_code
              this.telsearch = response.data[0].invite_code
              if (type === 'name') {
                this.$swal({
                  icon: 'success',
                  title: '<h3 style="color: #000">ค้นหาสำเร็จ</h3>',
                  text: `พบข้อมูลชื่อผู้แนะนำ ${response.data.length} ราย`,
                  customClass: {
                    confirmButton: 'btn btn-warning',
                  },
                })
              }
            } else {
              this.telsearch = null
              this.optionsCheck = false
              this.$swal({
                icon: 'danger',
                title: '<h3 style="color: #000">แจ้งเตือน !</h3>',
                text: 'ไม่พบข้อมูลผู้แนะนำ',
                customClass: {
                  confirmButton: 'btn btn-warning',
                },
              })
            }
            this.optionsMemShow = false
            this.optionsMember = response.data
          })
          .catch(error => console.log(error))
      } else {
        this.optionsMemShow = false
        this.optionsMember = []
        this.optionsCheck = false
        this.$swal({
          icon: 'danger',
          title: '<h3 style="color: #000">แจ้งเตือน !</h3>',
          text: 'กรุณากรอกชื่อผู้แนะนำเพื่อค้นหา',
          customClass: {
            confirmButton: 'btn btn-warning',
          },
        })
      }
    },
    Get_bank() {
      this.$http
        .get('/bank/korean')
        .then(response => {
          this.banklist = response.data
        })
        .catch(error => console.log(error))
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.zean === true) {
            this.zean = 1
          } else {
            this.zean = 0
          }
          const params = {
            fullname: this.fullname,
            password: this.password,
            bank: this.bank,
            username: this.username,
            acc_no: this.acc_no,
            // trueaccno: this.trueaccno,
            status: this.status,
            zean: this.zean,
            zean_percent: this.zean_percent,
            email: this.email,
            invited_by: this.invited_by ? this.invited_by : null,
          }
          this.$http
            .post(
              '/users/store',
              params,
            )
            // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.show = false
              this.Success('เพิ่มลูกค้าเรียบร้อย')
              this.ClearData()
              // this.$router.push({ name: 'member' })
            })
            .catch(error => {
              // console.log(error)
              this.SwalError(error.response.data.message)
            })
        }
      })
    },
    ClearData() {
      this.fullname = ''
      this.password = ''
      this.surname = ''
      this.bank = ''
      this.tel = ''
      this.acc_no = ''
      this.trueaccno = ''
      this.status = ''
      this.zean = false
      this.zean_percent = 0
      this.email = ''
      this.invited_by = ''
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #000">Error!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>
<style>
  .card-warning {
    background-color: #f4bb00;
    /* border-color: #f8f9fa; */
    /* padding: 10px; */
  }
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.form-item-section {
  background-color: $product-details-bg;
}
</style>
